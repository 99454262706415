import { Heading } from '@chakra-ui/react'
import { Fragment, useEffect, useState } from 'react'
import movistar_logo from '../../images/movistar.png';
import telcel_logo from '../../images/telcel.png';
import att_logo from '../../images/att.png';
import services_logo from '../../images/services.jpg';
import { Col, Container, Row } from 'react-bootstrap';
import Item from './Balances/Item';
import { useSelector } from 'react-redux';
import { getDrisslyBalance } from '../../api/Drissly';

const Balances = () => {
    const token_drissly = useSelector(state => state.login.token_drissly);
    const headers = { Authorization: `Bearer ${token_drissly}` };
    const [balances, setBalances] = useState({
        telcel: 0,
        movistar: 0,
        att: 0,
        services: 0
    });
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        getBalances();
    }, []);

    const getBalances = async () => {
        let balances = {};
        let response = await getDrisslyBalance({ headers, id_carrier: 2 });
        if (response)
            balances.movistar = {
                spended: response.spended_balance,
                assigned: response.assigned_balance,
                remaining: response.remaining_balance
            };

        response = await getDrisslyBalance({ headers, id_carrier: 1, region: 'multi' });
        if (response)
            balances.telcel = {
                spended: response.spended_balance,
                assigned: response.assigned_balance,
                remaining: response.remaining_balance
            };
        response = await getDrisslyBalance({ headers, id_carrier: 3 });
        if (response)
            balances.att = {
                spended: response.spended_balance,
                assigned: response.assigned_balance,
                remaining: response.remaining_balance
            }
        response = await getDrisslyBalance({ headers, id_carrier: 4 });
        if (response)
            balances.services = {
                spended: response.spended_balance,
                assigned: response.assigned_balance,
                remaining: response.remaining_balance
            }
        setBalances(balances);
        setLoading(false);
    }
    return (
        <Fragment>
            <Heading>Saldo actual</Heading>
            <Container fluid>
                <Row className='mt-5'>
                    <Col sm={12} md={6} lg={6} xl={3} className='mt-3'>
                        <Item
                            loading={loading}
                            logo={telcel_logo}
                            spended={balances?.telcel?.spended}
                            remaining={balances?.telcel?.remaining}
                            assigned={balances?.telcel?.assigned}
                            carrier='TELCER'
                        />
                    </Col>
                    <Col sm={12} md={6} lg={6} xl={3} className='mt-3'>
                        <Item
                            loading={loading}
                            logo={movistar_logo}
                            spended={balances?.movistar?.spended}
                            remaining={balances?.movistar?.remaining}
                            assigned={balances?.movistar?.assigned}
                            carrier='MOVISTAR'
                        />
                    </Col>
                    <Col sm={12} md={6} lg={6} xl={3} className='mt-3'>
                        <Item
                            loading={loading}
                            logo={att_logo}
                            spended={balances?.att?.spended}
                            remaining={balances?.att?.remaining}
                            assigned={balances?.att?.assigned}
                            carrier='AT&T'
                        />
                    </Col>
                    <Col sm={12} md={6} lg={6} xl={3} className='mt-3'>
                        <Item
                            loading={loading}
                            logo={services_logo}
                            spended={balances?.services?.spended}
                            remaining={balances?.services?.remaining}
                            assigned={balances?.services?.assigned}
                            carrier='AT&T'
                        />
                    </Col>
                </Row>
            </Container>
        </Fragment>
    )
}

export default Balances